<template>
  <router-view></router-view>
</template>

<script setup>
import { ref, provide, watchEffect } from 'vue'

const currentLanguage = ref(localStorage.getItem('language') || 'en')

const toggleLanguage = () => {
  currentLanguage.value = currentLanguage.value === 'en' ? 'zh' : 'en'
}

watchEffect(() => {
  localStorage.setItem('language', currentLanguage.value)
})

provide('currentLanguage', currentLanguage)
provide('toggleLanguage', toggleLanguage)
</script>