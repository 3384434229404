import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '@/views/IndexView.vue'
import IntroView from '@/views/IntroView.vue'
import SignIn from '@/components/SignIn.vue'
import ResumeUploader from '@/components/ResumeUploader.vue'
import ResumeResults from '@/components/ResumeResults.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: IndexView
  },
  {
    path: '/offerwink',
    name: 'OfferWink',
    component: IntroView
  },
  {
    path: '/upload',
    name: 'ResumeUploader',
    component: ResumeUploader
  },
  {
    path: '/results',
    name: 'ResumeResults',
    component: ResumeResults
  },
  {
    path: '/signin',
    name: 'SignIn',
    component:SignIn
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router