<template>
  <div class="flex flex-col min-h-screen">
    <header class="px-4 lg:px-6 h-14 flex items-center justify-center">
      <div class="container mx-auto flex items-center justify-between">
        <router-link class="flex items-center justify-center" to="/">
          <FileText class="h-6 w-6 mr-2 text-blue-500" />
          <span class="font-bold text-xl">OfferWink</span>
        </router-link>
        <nav class="flex gap-4 sm:gap-6">
          <a class="text-sm font-medium hover:underline underline-offset-4" href="#features">{{ translations[currentLanguage].features }}</a>
          <a class="text-sm font-medium hover:underline underline-offset-4" href="#how-it-works">{{ translations[currentLanguage].howItWorks }}</a>
          <a class="text-sm font-medium hover:underline underline-offset-4" href="#pricing">{{ translations[currentLanguage].pricing }}</a>
        </nav>
        <div class="flex items-center">
          <button @click="toggleLanguage" class="ml-4 text-sm font-medium hover:underline underline-offset-4">
            {{ currentLanguage === 'en' ? '中文' : 'English' }}
          </button>
        </div>
      </div>
    </header>
    <main class="flex-1">
      <section class="w-full py-12 md:py-24 lg:py-32 xl:py-48 bg-gradient-to-b from-blue-50 to-white">
        <div class="container mx-auto px-4 md:px-6">
          <div class="flex flex-col items-center space-y-4 text-center">
            <div class="space-y-2">
              <h1 class="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
                OfferWink
              </h1>
              <h2 class="text-base font-semibold tracking-tight sm:text-xl md:text-2xl lg:text-3xl/none my-6">
                {{ translations[currentLanguage].tagline }}
              </h2>
              <p class="mx-auto max-w-[700px] text-gray-500 md:text-xl dark:text-gray-400">
                {{ translations[currentLanguage].description }}
              </p>
            </div>
            <div class="space-x-4">
              <router-link 
                to="/upload" 
                class="inline-flex items-center justify-center rounded-md text-base font-semibold transition-all duration-300 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-blue-600 text-white hover:bg-blue-700 shadow-lg hover:shadow-xl px-6 py-3 transform hover:-translate-y-0.5"
              >
                {{ translations[currentLanguage].startButton }}
                <ArrowRight class="ml-2 h-5 w-5" />
              </router-link>
            </div>
          </div>
        </div>
      </section>
      <section id="features" class="w-full py-12 md:py-24 lg:py-32">
        <div class="container mx-auto px-4 md:px-6">
          <h2 class="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">
            {{ translations[currentLanguage].mainFeatures }}
          </h2>
          <div class="grid gap-10 sm:grid-cols-2 md:grid-cols-3">
            <div class="flex flex-col items-center text-center">
              <Zap class="h-12 w-12 mb-4 text-blue-500" />
              <h3 class="text-xl font-bold mb-2">{{ translations[currentLanguage].aiDrivenAnalysis }}</h3>
              <p class="text-gray-500 dark:text-gray-400">{{ translations[currentLanguage].aiDrivenAnalysisDescription }}</p>
            </div>
            <div class="flex flex-col items-center text-center">
              <FileText class="h-12 w-12 mb-4 text-green-500" />
              <h3 class="text-xl font-bold mb-2">{{ translations[currentLanguage].personalizedResume }}</h3>
              <p class="text-gray-500 dark:text-gray-400">{{ translations[currentLanguage].personalizedResumeDescription }}</p>
            </div>
            <div class="flex flex-col items-center text-center">
              <CheckCircle class="h-12 w-12 mb-4 text-yellow-500" />
              <h3 class="text-xl font-bold mb-2">{{ translations[currentLanguage].interviewPreparation }}</h3>
              <p class="text-gray-500 dark:text-gray-400">{{ translations[currentLanguage].interviewPreparationDescription }}</p>
            </div>
          </div>
        </div>
      </section>
      <section id="how-it-works" class="w-full py-12 md:py-24 lg:py-32 bg-gray-50 overflow-hidden">
        <div class="container mx-auto px-4 md:px-6">
          <h2 class="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">
            {{ translations[currentLanguage].howItWorks }}
          </h2>
          <div class="relative">
            <div class="absolute top-1/2 left-0 w-full h-0.5 bg-blue-200 transform -translate-y-1/2"></div>
            <div class="grid gap-8 relative z-10 md:grid-cols-3">
              <div v-for="(step, index) in steps" :key="index" class="flex flex-col items-center text-center">
                <div
                  :class="`w-24 h-24 bg-blue-500 rounded-full flex items-center justify-center text-white text-4xl font-bold mb-4 relative z-10 transition-all duration-500 ${
                    activeStep === index ? 'scale-110 shadow-lg' : ''
                  }`"
                  @mouseenter="setActiveStep(index)"
                >
                  <component :is="step.icon" class="w-12 h-12" />
                </div>
                <div :class="`bg-white p-6 rounded-lg shadow-lg transition-all duration-500 h-full ${
                  activeStep === index ? 'scale-105 shadow-xl' : ''
                }`">
                  <h3 class="text-xl font-bold mb-2">{{ step.title }}</h3>
                  <p class="text-gray-500">{{ step.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="privacy" class="w-full py-12 md:py-24 lg:py-32">
        <div class="container mx-auto px-4 md:px-6">
          <div class="flex flex-col md:flex-row items-center justify-between">
            <div class="md:w-1/2 mb-8 md:mb-0">
              <h2 class="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl mb-4">
                {{ translations[currentLanguage].privacySecurity }}
              </h2>
              <p class="text-xl text-gray-500 dark:text-gray-400">
                {{ translations[currentLanguage].privacyDescription }}
              </p>
            </div>
            <div class="md:w-1/2 flex justify-center">
              <Shield class="h-48 w-48 text-blue-500" />
            </div>
          </div>
        </div>
      </section>
      <section id="pricing" class="w-full py-12 md:py-24 lg:py-32 bg-gray-50">
        <div class="container mx-auto px-4 md:px-6">
          <h2 class="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">
            {{ translations[currentLanguage].pricingPlans }}
          </h2>
          <div class="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 max-w-5xl mx-auto">
            <div v-for="(plan, index) in pricingPlans" :key="index"
                 :class="`flex flex-col p-6 bg-white rounded-lg shadow-lg transition-all duration-300 hover:-translate-y-2 hover:shadow-xl ${selectedPlan === index ? 'border-2 border-blue-500' : ''}`"
                 @click="selectPlan(index)">
              <h3 class="text-2xl font-bold mb-4">{{ plan.name }}</h3>
              <p class="text-4xl font-bold mb-4">{{ plan.price }}<span class="text-base font-normal">{{ plan.period }}</span></p>
              <ul class="mb-6 space-y-2 flex-grow">
                <li v-for="feature in plan.features" :key="feature" class="flex items-center">
                  <CheckCircle class="h-5 w-5 mr-2 text-green-500" />
                  {{ feature }}
                </li>
              </ul>
              <button class="mt-auto inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2">
                {{ plan.buttonText }}
              </button>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full py-12 md:py-24 lg:py-32 bg-gray-50 overflow-hidden">
        <div class="container mx-auto px-4 md:px-6">
          <h2 class="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">
            {{ translations[currentLanguage].testimonialsTitle }}
          </h2>
          <div class="relative">
            <div class="flex flex-wrap justify-center gap-8">
              <div v-for="(testimonial, index) in testimonials" :key="index" 
                   :class="`bg-white p-6 rounded-lg shadow-lg w-full sm:w-[calc(50%-1rem)] md:w-[calc(33.333%-1.5rem)] lg:w-[calc(25%-1.5rem)] xl:w-[calc(20%-1.6rem)] testimonial-card testimonial-card-${index + 1} flex flex-col`">
                <p class="text-gray-600 mb-4 flex-grow">{{ testimonial.content }}</p>
                <div class="flex items-center mt-auto">
                  <img :src="testimonial.avatar" alt="User avatar" class="w-12 h-12 rounded-full mr-4" />
                  <div>
                    <h4 class="font-bold">{{ testimonial.name }}</h4>
                    <p class="text-sm text-gray-500">{{ testimonial.position }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer class="py-6 w-full shrink-0 px-4 md:px-6 border-t">
      <div class="container mx-auto flex justify-center items-center">
        <p class="text-xs text-center text-gray-500 dark:text-gray-400" v-html="translations[currentLanguage].footer">
        </p>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref, reactive, computed, inject } from 'vue'
import { ArrowRight, CheckCircle, FileText, Shield, Zap, Upload, Brain, Download } from 'lucide-vue-next'

const currentLanguage = inject('currentLanguage', 'en')
const toggleLanguage = inject('toggleLanguage', () => {})

const translations = reactive({
  zh: {
    tagline: '您的简历优化专家',
    description: 'AI驱动的简历优化工具，智能分析您的简历，结合岗位目标，提供专业优化建议，生成个性化简历和面试题答案，助您脱颖而出。',
    startButton: '免费开始',
    mainFeatures: '主要特性',
    aiDrivenAnalysis: 'AI驱动分析',
    aiDrivenAnalysisDescription: '智能分析您的简历，提供专业优化建议',
    personalizedResume: '个性化简历',
    personalizedResumeDescription: '生成专属于您的个性化简历，突出您的优势',
    interviewPreparation: '面试准备',
    interviewPreparationDescription: '生成配套面试题及答案，助您应对面试挑战',
    howItWorks: '工作原理',
    privacySecurity: '您的隐私安全是我们的首要任务',
    privacyDescription: '简历优化专家不会保存您上传的任何个人信息。您的数据在处理后会立即被删除，确保您的隐私得到最大程度的保护。',
    pricingPlans: '价格方案',
    testimonialsTitle: '想和他们一样拿到心仪Offer吗？',
    footer: '© 2024 <a href="https://wink.run" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:underline">wink.run</a> 出品。保留所有权利。了解我们的<a href="https://wink.run/policy.html" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:underline">隐私权政策</a>。',
    features: '特性',
    pricing: '价格',
    freePlan: '免费版',
    perUse: '按次付费',
    monthlyPlan: '包月版',
    forever: '/永久',
    perTime: '/次',
    perMonth: '/月',
    basicAIModel: '基础AI模型分析',
    resumeSuggestions: '简历优化建议',
    basicTemplates: '基础简历模板',
    advancedAIModel: '高级AI模型支持',
    deepAnalysis: '深度简历分析和优化',
    advancedTemplates: '高级简历模板',
    interviewQA: '个性化面试问题和答案',
    unlimitedUse: '无限次使用',
    latestAIModel: '最新AI模型支持',
    allAdvancedFeatures: '全部高级功能',
    premiumTemplates: '精美简历模板随心换',
    prioritySupport: '优先客户支持',
    startFree: '免费开始',
    buyNow: '立即购买',
    subscribe: '订阅包月',
    priceFree: '¥0',
    pricePerUse: '¥9',
    priceMonthly: '¥199'
  },
  en: {
    tagline: 'Your Resume Optimization Expert',
    description: 'AI-driven resume optimization tool that intelligently analyzes your resume, aligns it with job targets, provides professional optimization suggestions, and generates personalized resumes and interview question answers to help you stand out.',
    startButton: 'Start for Free',
    mainFeatures: 'Main Features',
    aiDrivenAnalysis: 'AI-Driven Analysis',
    aiDrivenAnalysisDescription: 'Intelligently analyzes your resume and provides professional optimization suggestions',
    personalizedResume: 'Personalized Resume',
    personalizedResumeDescription: 'Generates a personalized resume that highlights your strengths',
    interviewPreparation: 'Interview Preparation',
    interviewPreparationDescription: 'Generates interview questions and answers to help you prepare',
    howItWorks: 'How It Works',
    privacySecurity: 'Your Privacy Security is Our Top Priority',
    privacyDescription: 'Resume Optimization Expert does not store any personal information you upload. Your data is deleted immediately after processing to ensure maximum privacy protection.',
    pricingPlans: 'Pricing Plans',
    testimonialsTitle: 'Want to get the Offer you desire like them?',
    footer: '© 2024 by <a href="https://wink.run" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:underline">wink.run</a>.  All rights reserved. Learn more about our <a href="https://wink.run/policy.html" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:underline">Privacy Policy</a>.',
    features: 'Features',
    pricing: 'Pricing',
    freePlan: 'Free Plan',
    perUse: 'Pay Per Use',
    monthlyPlan: 'Monthly Plan',
    forever: '/forever',
    perTime: '/time',
    perMonth: '/month',
    basicAIModel: 'Basic AI Model Analysis',
    resumeSuggestions: 'Resume Optimization Suggestions',
    basicTemplates: 'Basic Resume Templates',
    advancedAIModel: 'Advanced AI Model Support',
    deepAnalysis: 'Deep Resume Analysis and Optimization',
    advancedTemplates: 'Advanced Resume Templates',
    interviewQA: 'Personalized Interview Q&A',
    unlimitedUse: 'Unlimited Usage',
    latestAIModel: 'Latest AI Model Support',
    allAdvancedFeatures: 'All Advanced Features',
    premiumTemplates: 'Premium Resume Templates',
    prioritySupport: 'Priority Customer Support',
    startFree: 'Start Free',
    buyNow: 'Buy Now',
    subscribe: 'Subscribe',
    priceFree: '$0',
    pricePerUse: '$1.99',
    priceMonthly: '$99'
  }
})

const activeStep = ref(0)

const steps = computed(() => [
  { 
    icon: Upload, 
    title: currentLanguage.value === 'zh' ? "上传简历" : "Upload Resume", 
    description: currentLanguage.value === 'zh' 
      ? "上传您当前的简历和目标岗位描述" 
      : "Upload your current resume and target job description"
  },
  { 
    icon: Brain, 
    title: currentLanguage.value === 'zh' ? "AI分析" : "AI Analysis", 
    description: currentLanguage.value === 'zh' 
      ? "我们的AI系统分析您的简历，并与岗位要求进行匹配" 
      : "Our AI system analyzes your resume and matches it with job requirements"
  },
  { 
    icon: Download, 
    title: currentLanguage.value === 'zh' ? "获取结果" : "Get Results", 
    description: currentLanguage.value === 'zh' 
      ? "接收优化建议、个性化简历和面试题及答案" 
      : "Receive optimization suggestions, personalized resume, and interview Q&A"
  },
])

const setActiveStep = (index) => {
  activeStep.value = index
}

const testimonials = computed(() => [
  {
    content: currentLanguage.value === 'zh' 
      ? "简历优化专家帮我重新梳理了职业经历，突出了我的核心优势。面试邀请明显增多了！"
      : "The Resume Optimization Expert helped me reorganize my professional experience, highlighting my core strengths. Interview invitations have significantly increased!",
    name: currentLanguage.value === 'zh' ? "张明" : "Zhang Ming",
    position: currentLanguage.value === 'zh' ? "软件工程师" : "Software Engineer",
    avatar: "https://i.pravatar.cc/150?img=3"
  },
  {
    content: currentLanguage.value === 'zh' 
      ? "AI生成的面试问题和答案给了我很大帮助，让我在面试中更加自信。强烈推荐！"
      : "The AI-generated interview questions and answers have been very helpful, making me more confident in interviews. Highly recommended!",
    name: currentLanguage.value === 'zh' ? "李华" : "Li Hua",
    position: currentLanguage.value === 'zh' ? "市场经理" : "Marketing Manager",
    avatar: "https://i.pravatar.cc/150?img=2"
  },
  {
    content: currentLanguage.value === 'zh' 
      ? "使用这个工具后，我的简历质量提升了一个档次。很快就收到了理想公司offer。"
      : "After using this tool, the quality of my resume improved significantly. I received an offer from my ideal company soon.",
    name: currentLanguage.value === 'zh' ? "王芳" : "Wang Fang",
    position: currentLanguage.value === 'zh' ? "人力资源专员" : "HR Specialist",
    avatar: "https://i.pravatar.cc/150?img=1"
  },
  {
    content: currentLanguage.value === 'zh' 
      ? "这个工具不仅优化了我的简历，还帮我发现了自己的优势。非常感谢！"
      : "This tool not only optimized my resume but also helped me discover my strengths. Thank you very much!",
    name: currentLanguage.value === 'zh' ? "刘强" : "Liu Qiang",
    position: currentLanguage.value === 'zh' ? "数据分析师" : "Data Analyst",
    avatar: "https://i.pravatar.cc/150?img=4"
  },
  {
    content: currentLanguage.value === 'zh' 
      ? "简历优化专家让我的求职过程变得轻松多了。强烈推荐给所有求职者！"
      : "The Resume Optimization Expert made my job search process much easier. Highly recommended for all job seekers!",
    name: currentLanguage.value === 'zh' ? "陈静" : "Chen Jing",
    position: currentLanguage.value === 'zh' ? "产品经理" : "Product Manager",
    avatar: "https://i.pravatar.cc/150?img=5"
  }
])

const selectedPlan = ref(null)

const selectPlan = (index) => {
  selectedPlan.value = index
}

const pricingPlans = computed(() => [
  {
    name: translations[currentLanguage.value].freePlan,
    price: translations[currentLanguage.value].priceFree,
    period: translations[currentLanguage.value].forever,
    features: [
      translations[currentLanguage.value].basicAIModel,
      translations[currentLanguage.value].resumeSuggestions,
      translations[currentLanguage.value].basicTemplates
    ],
    buttonText: translations[currentLanguage.value].startFree
  },
  {
    name: translations[currentLanguage.value].perUse,
    price: translations[currentLanguage.value].pricePerUse,
    period: translations[currentLanguage.value].perTime,
    features: [
      translations[currentLanguage.value].advancedAIModel,
      translations[currentLanguage.value].deepAnalysis,
      translations[currentLanguage.value].advancedTemplates,
      translations[currentLanguage.value].interviewQA
    ],
    buttonText: translations[currentLanguage.value].buyNow
  },
  {
    name: translations[currentLanguage.value].monthlyPlan,
    price: translations[currentLanguage.value].priceMonthly,
    period: translations[currentLanguage.value].perMonth,
    features: [
      translations[currentLanguage.value].unlimitedUse,
      translations[currentLanguage.value].latestAIModel,
      translations[currentLanguage.value].allAdvancedFeatures,
      translations[currentLanguage.value].premiumTemplates,
      translations[currentLanguage.value].prioritySupport
    ],
    buttonText: translations[currentLanguage.value].subscribe
  }
])
</script>

<style scoped>
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.85;
  }
}

.router-link-active {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.testimonial-card {
  animation: float 6s ease-in-out infinite;
}

.testimonial-card:nth-child(2) {
  animation-delay: 1s;
}

.testimonial-card:nth-child(3) {
  animation-delay: 2s;
}

.testimonial-card:nth-child(4) {
  animation-delay: 3s;
}

.testimonial-card:nth-child(5) {
  animation-delay: 4s;
}
</style>