import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/main.css'  // 确保这行存在
import 'highlight.js/styles/github.css';
import { clerkPlugin } from 'vue-clerk';

const app = createApp(App)

const clerkPubKey = process.env.VUE_APP_CLERK_PUBLISHABLE_KEY

if (!clerkPubKey) {
  throw new Error("Missing Publishable Key")
}

app.use(clerkPlugin, {
  publishableKey: clerkPubKey,
})

app.use(router)
app.mount('#app')